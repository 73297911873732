<template>
    <div class="row justify-content-center">
        <div class="col-lg-12">
            <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden mb-4">
                <ul class="nav nav-tabs xs-p-4 d-flex align-items-center justify-content-around product-info-tab border-bottom-0" id="pills-tab" role="tablist">
                    <li class="list-inline-item active"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase active" href="#navtabs0" data-toggle="tab">View</a></li>
                    <!-- <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs1" data-toggle="tab">Learning Goals</a></li> -->
                    <li class="list-inline-item"><a class="fw-700 pb-sm-4 pt-sm-4 xs-mb-2 font-xssss text-grey-500 ls-3 d-inline-block text-uppercase" href="#navtabs2" data-toggle="tab">Outcomes</a></li>
                </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
                <div class="tab-pane fade show active" id="navtabs0">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Views></Views>
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs1">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <LearningGoals></LearningGoals>
                    </div>
                </div>
                <div class="tab-pane fade" id="navtabs2">
                    <div class="form-group p-3 border-light border p-2 shadow-xs bg-white rounded-lg">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="row m-0 p-0">    
                                    <div class="form-group col-12 icon-input mb-0">
                                        <i class="ti-reload font-xs text-grey-400"></i>
                                        <input type="text" class="style1-input border-0 pl-5 font-xsss mb-0 text-grey-800 fw-500 bg-transparent w-100" :value="`Term ${cycle}`" disabled>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden p-4">
                        <Outcomes :cycle="cycle"></Outcomes>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import Views from './Sub/Views.vue'
import LearningGoals from './Sub/LearningGoals.vue'
import Outcomes from './Sub/Outcomes.vue'
export default {
    name: 'Attitudes',
    components:{
        Views,
        LearningGoals,
        Outcomes,
    },
    data(){
        return {
            slug: this.$route.params.idCourse,
            cycles: [],
            cycle: ''
        }
    },
    created(){
        this.getCycles()
    },
    methods: {
        async getCycles(){
            if(localStorage.getItem(`${this.slug}-cycles`)) {
                this.cycles = JSON.parse(localStorage.getItem(`${this.slug}-cycles`))
            } else {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/course/cycles?slug=${this.slug}&is_active=true`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    this.cycles = res.data.data
                    for(let i = 0; i < this.cycles.length; i++) {
                        if(this.cycles[i].active) {
                            this.cycle = this.cycles[i].cycle
                            break;
                        }
                    }
                }).catch((err) => console.error(err))
            }
            if(localStorage.getItem(`${this.slug}-cycle`)) {
                this.cycle = localStorage.getItem(`${this.slug}-cycle`)
            } else {
                this.cycle = this.cycles[0].cycle
            }
        },
    }
}
</script>