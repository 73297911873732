<template>
    <div class="row">
        <div v-if="!isLoad" class="col-12">
            <div class="row text-black py-5">
                <div class="col-12 d-flex align-items-center justify-content-center mb-3">
                    <img src="/images/logo-1-new.png" width="40">
                </div>
                <div class="col-12 text-center mb-3">
                    <b><i>Elingway: Learning Any Language Differenly</i></b>
                </div>
                <div class="col-12 text-center mb-3">
                    <b>Assessment Progress Report</b>
                </div>
                <div class="col-12 text-left mb-4">
                    <table class="table table-bordered text-black">
                        <thead>
                            <tr>
                                <td width="20%">Type Of Progress Report</td>
                                <td>Term {{cycle}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Name of Course Category</td>
                                <td>{{report.course_name}}</td>
                            </tr>
                            <tr>
                                <td>Name of Student</td>
                                <td>{{report.user_name}}</td>
                            </tr>
                            <tr>
                                <td>Student University</td>
                                <td>{{report.user_university}}</td>
                            </tr>
                            <tr>
                                <td>Date of Report</td>
                                <td>{{report.date_report | formatDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 text-center mb-4">
                    <b>Measurable Short-term Objectives</b>
                </div>
                <div class="col-12 text-left mb-1">
                    <table class="table table-bordered text-black">
                        <thead>
                            <tr>
                                <td>CEFR Mapping Objective</td>
                                <td>Descriptor</td>
                                <td>Materials</td>
                                <td>Assessment Tasks</td>
                                <td>CEFR Level Overview</td>
                                <td>Final Remarks</td>
                                <td>Meaning</td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in report.objectives" :key="index">
                            <td>{{item.mapping_objective_component}}</td>
                            <td>{{item.mapping_objective_descriptor}}</td>
                            <td>{{item.material_title}}</td>
                            <td>{{item.assignment_title}}</td>
                            <td>{{item.level_overview_level}}</td>
                            <td>{{item.level_overview_final_remarks}}</td>
                            <td>{{item.level_overview_meaning}}</td>
                        </tr>
                        <tr v-if="report.objectives.length == 0" >
                            <td colspan="8" class="text-center bg-grey">No Objectives</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 text-left mb-3">
                    <table class="table table-bordered text-black">
                        <tbody>
                            <tr>
                                <td width="25%">Were Objectives Met?</td>
                                <td><i class="fas" :class="{'fa-check': report.objective_met, 'fa-times': !report.objective_met}"></i></td>
                            </tr>
                            <tr>
                                <td width="25%">If no, why not?</td>
                                <td>{{report.not_reason}}</td>
                            </tr>
                            <tr>
                                <td width="25%">Additional Comments:</td>
                                <td>{{report.comment}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="col-12 text-left mb-3">
                    <table class="table border-0 text-black">
                        <tr>
                            <td>Authorized Signature: </td>
                            <td class="text-right"><img v-if="report.signature" :src=" report.signature" alt="..." width="100"></td>
                        </tr>
                        <tr>
                            <td>Date Sent to Parents: </td>
                            <td class="text-right">{{report.date_send_parent | formatDate}}</td>
                        </tr>
                        <tr>
                            <td>Date Sent to University: </td>
                            <td class="text-right">{{report.date_send_university | formatDate}}</td>
                        </tr>
                    </table>
                </div>
                <div class="col-12 text-right">
                    <button @click="generateReport()" class="btn btn-danger">Export Report</button>  
                </div>
            </div>
            <vue-html2pdf
                :enable-download="true"
                filename="hehehe"
                :pdf-quality="2"
                pdf-format="a4"
                pdf-orientation="portrait"
                :manual-pagination="true"
                ref="html2Pdf"
                :html-to-pdf-options="htmlToPdfOptions"
            >
                <section slot="pdf-content">
                      <section class="pdf-item">
                        <div class="row text-black font-xssss pt-3">
                            <div class="col-12 d-flex align-items-center justify-content-center mb-3">
                                <img src="/images/logo-1-new.png" width="40">
                            </div>
                            <div class="col-12 text-center mb-3">
                                <b><i>Elingway: Learning Any Language Differenly</i></b>
                            </div>
                            <div class="col-12 text-center mb-3">
                                <b>Assessment Progress Report</b>
                            </div>
                            <div class="col-12 text-left mb-2">
                                <table class="table table-bordered text-black">
                                    <thead>
                                        <tr>
                                            <td width="20%">Type Of Progress Report</td>
                                            <td>Term {{cycle}}</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Name of Course Category</td>
                                            <td>{{report.course_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Name of Student</td>
                                            <td>{{report.user_name}}</td>
                                        </tr>
                                        <tr>
                                            <td>Student University</td>
                                            <td>{{report.user_university}}</td>
                                        </tr>
                                        <tr>
                                            <td>Date of Report</td>
                                            <td>{{report.date_report | formatDate}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-center mb-2">
                                <b>Measurable Short-term Objectives</b>
                            </div>
                            <div class="col-12 text-left mb-1">
                                <table class="table table-bordered text-black">
                                    <thead>
                                        <tr>
                                            <td>CEFR Mapping Objective</td>
                                            <td>Descriptor</td>
                                            <td>Materials</td>
                                            <td>Assessment Tasks</td>
                                            <td>CEFR Level Overview</td>
                                            <td>Final Remarks</td>
                                            <td>Meaning</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item, index) in report.objectives" :key="index">
                                        <td>{{item.mapping_objective_component}}</td>
                                        <td>{{item.mapping_objective_descriptor}}</td>
                                        <td>{{item.material_title}}</td>
                                        <td>{{item.assignment_title}}</td>
                                        <td>{{item.level_overview_level}}</td>
                                        <td>{{item.level_overview_final_remarks}}</td>
                                        <td>{{item.level_overview_meaning}}</td>
                                    </tr>
                                    <tr v-if="report.objectives.length == 0" >
                                        <td colspan="8" class="text-center bg-grey">No Objectives</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-left mb-2">
                                <table class="table table-bordered text-black">
                                    <tbody>
                                        <tr>
                                            <td width="25%">Were Objectives Met?</td>
                                            <td><i class="fas" :class="{'fa-check': report.objective_met, 'fa-times': !report.objective_met}"></i></td>
                                        </tr>
                                        <tr>
                                            <td width="25%">If no, why not?</td>
                                            <td>{{report.not_reason}}</td>
                                        </tr>
                                        <tr>
                                            <td width="25%">Additional Comments:</td>
                                            <td>{{report.comment}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-12 text-left mb-2">
                            <table class="table border-0 text-black">
                                <tr>
                                    <td>Authorized Signature: </td>
                                    <td class="text-right"><img v-if="report.signature" :src=" report.signature" alt="..." width="100"></td>
                                </tr>
                                <tr>
                                    <td>Date Sent to Parents: </td>
                                    <td class="text-right">{{report.date_send_parent | formatDate}}</td>
                                </tr>
                                <tr>
                                    <td>Date Sent to University: </td>
                                    <td class="text-right">{{report.date_send_university | formatDate}}</td>
                                </tr>
                            </table>
                            </div>
                        </div>
                    </section>
                </section>
            </vue-html2pdf>
        </div>
        <div v-else class="col-12">
            <div class="row">
                <div class="col-12 text-center py-3">
                <div class="my-3">
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-warning mx-3" role="status">
                    <span class="sr-only">Loading...</span>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: ['cycle'],
    created(){
        this.getReport();
    },
    computed: {
        htmlToPdfOptions() {
            return {
                margin: 0,
                filename: "Report.pdf",
                image: {
                    type: "jpeg",
                    quality: 2,
                },
                enableLinks: true,
                html2canvas: {
                    scale: 2,
                    useCORS: true,
                },
                jsPDF: {
                    unit: "in",
                    format: 'a4',
                    orientation: 'portrait',
                },
            };
        },
    },
    data(){
        return {
            isLoad: true,
            slug: this.$route.params.idCourse,
            media: process.env.VUE_APP_URL_CLOUD,
            report: {
                id: '',
                course_id: '',
                course_name: '',
                user_id: '',
                user_name: '',
                user_university: '',
                cycle: 1,
                publish: false,
                date_report: '',
                objective_met: true,
                not_reason: '',
                comment: '',
                signature: '',
                date_send_parent: '',
                date_send_university: '',
                objectives: []
            }
        }
    },
    methods: {
        generateReport () {
            this.$refs.html2Pdf.generatePdf()
        },
        cycleChange(){
            this.isLoad = true;
            this.getReport();
        },
        async getReport() {
            if(this.cycle) {
                await axios.get(`${process.env.VUE_APP_URL_API}/core/evaluation/reward-recognition?slug=${this.slug}&cycle=${this.cycle}`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.token}`
                    }
                }).then(res => {
                    if(res.data.success) {
                        this.report = res.data.data
                        this.isLoad = false
                    }
                }).catch(() => {
                    this.$swal({
                        toast: true,
                        title: 'Report',
                        text: 'Failed Load Data!',
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                })
            }
        },
    },
    watch: {
        cycle:function(){
            this.isLoad = true
            this.cycleChange()
        }
    }
}
</script>